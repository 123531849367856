import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import styles from "./index.module.less";
import ClassNames from "classnames";
import { Dropdown, Menu } from "antd";
import { RouterContext, MRouteProps } from "routes";
import { ContactManager, ENV } from "utils";

const renderTabItem = (
  item: MRouteProps,
  navigate: (path: string) => void,
  isCurrent?: boolean
) => {
  if (item.path === "/contact") {
    return (
      <div
        className={styles.tabItem}
        key={item.path}
        onClick={() => {
          if (ENV.isPC) {
            ContactManager.open();
          } else {
            const el = document.getElementsByTagName("footer")[0];
            if (el)
              el.scrollIntoView({
                behavior: "smooth",
              });
          }
        }}
      >
        <div className={styles.inactivate}>{item.name}</div>
      </div>
    );
  }
  return (
    <div
      className={styles.tabItem}
      key={item.path}
      onClick={() => navigate(item.path)}
    >
      <div className={isCurrent ? styles.activate : styles.inactivate}>
        {item.name}
      </div>
      {isCurrent && <div className={styles.bottomSlider} />}
    </div>
  );
};

export default function Header() {
  const location = useLocation();
  const history = useHistory();
  const routes = React.useContext(RouterContext);
  const tabs = routes.concat({ path: "/contact", name: "联系我们" });

  const navigate = useCallback((path: string) => {
    history.push(path);
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.col}>
        <img
          src={require("assets/images/header/logo.png")}
          alt=""
          className={styles.logo}
        />
      </div>
      <div className={ClassNames(styles.col, styles.right)}>
        {tabs.map((item) =>
          renderTabItem(item, navigate, location.pathname === item.path)
        )}
      </div>
      <div className={styles.mobileRight}>
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              {tabs.map((item) => (
                <Menu.Item key={item.path}>
                  {renderTabItem(item, navigate)}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <img src={require("assets/images/header/menu.png")} alt="" />
        </Dropdown>
      </div>
    </div>
  );
}
