import { ImageModalRef } from "components/Modal/ImageModal.tsx";

export default class ImageManager {
  public static modal: ImageModalRef | null = null;

  public static setModal(modal: ImageModalRef) {
    this.modal = modal;
  }

  public static open(source: string) {
    this.modal?.open(source);
  }
}
