import { ContactModalRef } from "components/Modal/ContactModal";

export default class ContactManager {
  public static manager: ContactModalRef;

  public static getManager(manager: ContactModalRef) {
    this.manager = manager;
  }

  public static open() {
    this.manager.open();
  }
}
