import { useSwitch } from "@huse/boolean";
import { Modal } from "antd";
import React, { useCallback, useImperativeHandle, useState } from "react";
import styles from "./index.module.less";

const ModalWidth = "100vw";

export interface ImageModalRef {
  open: (source: string) => void;
}

const ImageModal = React.forwardRef<ImageModalRef, {}>((props, ref) => {
  const [visible, on, off] = useSwitch();
  const [source, setSource] = useState<string>();

  const open = useCallback(
    (source: string) => {
      setSource(source);
      on();
    },
    [on]
  );

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Modal
      className={styles.scaleContainer}
      visible={visible}
      width={ModalWidth}
      closable={false}
      footer={null}
      onCancel={off}
    >
      <img src={source} alt="" style={{ width: ModalWidth }} />
    </Modal>
  );
});

export default ImageModal;
