import { Layout } from "antd";
import ContactModal from "components/Modal/ContactModal";
import ImageModal from "components/Modal/ImageModal.tsx";
import { FullscreenLoading, Header, Footer } from "components/Views";
import React, { Fragment, Suspense } from "react";
import { Redirect, Route, Router, Switch } from "react-router";
import { mobileRoutes, pcRoutes, RouterContext } from "routes";
import "./App.css";
import { ContactManager, ENV, historyService, ImageManager } from "./utils";

function App() {
  const routes = ENV.isPC ? pcRoutes : mobileRoutes;

  return (
    <Fragment>
      <RouterContext.Provider value={routes}>
        <Router history={historyService.service}>
          <Suspense fallback={<FullscreenLoading />}>
            <Switch>
              <Redirect exact from="/" to="/home" />
              {routes.map((item) => {
                const Component = item.component as any;
                return (
                  <Route
                    exact
                    key={item.path}
                    path={item.path}
                    render={(props) => (
                      <Layout>
                        <Layout.Header>
                          <Header />
                        </Layout.Header>
                        <Layout.Content>
                          <Component {...props} />
                        </Layout.Content>
                        {item.hasFooter !== false && (
                          <Layout.Footer>
                            <Footer />
                          </Layout.Footer>
                        )}
                      </Layout>
                    )}
                  />
                );
              })}
            </Switch>
          </Suspense>
        </Router>
        <ContactModal ref={(r) => r && ContactManager.getManager(r)} />
        <ImageModal ref={(r) => r && ImageManager.setModal(r)} />
      </RouterContext.Provider>
    </Fragment>
  );
}

export default App;
