import React from "react";
import { RouteProps } from "react-router";

const Home = React.lazy(() => import("screens/home"));
const Solution = React.lazy(() => import("screens/solution"));
const About = React.lazy(() => import("screens/about"));

export interface MRouteProps extends RouteProps {
  name: string;
  path: string;
  hasFooter?: boolean;
}

export const pcRoutes: MRouteProps[] = [
  {
    path: "/home",
    component: Home,
    name: "首页",
  },
  {
    path: "/solution",
    component: Solution,
    name: "解决方案",
  },
  {
    path: "/about",
    component: About,
    name: "关于我们",
  },
];

const HomeMobile = React.lazy(() => import("screens/mobile/home"));
const SolutionMobile = React.lazy(() => import("screens/mobile/solution"));
const AboutMobile = React.lazy(() => import("screens/mobile/about"));

export const mobileRoutes: MRouteProps[] = [
  {
    path: "/home",
    component: HomeMobile,
    name: "首页",
  },
  {
    path: "/solution",
    component: SolutionMobile,
    name: "解决方案",
  },
  {
    path: "/about",
    component: AboutMobile,
    name: "关于我们",
  },
];

export const RouterContext = React.createContext(pcRoutes);
