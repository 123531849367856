import { useSwitch } from "@huse/boolean";
import { Modal } from "antd";
import React, { forwardRef, useImperativeHandle } from "react";
import styles from "./index.module.less";
import { CloseCircleOutlined } from "@ant-design/icons";
import ClassNames from "classnames";

export interface ContactModalRef {
  open: () => void;
}

interface ContactModalProps {}

const ContactModal = forwardRef<ContactModalRef, ContactModalProps>(
  (_, ref) => {
    const [visible, open, close] = useSwitch(false);

    useImperativeHandle(ref, () => ({
      open,
    }));

    return (
      <Modal
        className={styles.modal}
        width="28.125vw"
        visible={visible}
        footer={null}
        closeIcon={<CloseCircleOutlined style={{ color: "white" }} />}
        onCancel={close}
      >
        <div className={ClassNames(styles.body, styles.left)}>
          <h1 className={styles.title}>联系我们</h1>
          <span className={styles.subtitle}>
            欢迎咨询，我们将竭诚为您服务！
          </span>
          <img
            src={require("assets/images/contact/contact.png")}
            alt="欢迎咨询！"
          />
        </div>
        <div className={ClassNames(styles.body, styles.right)}>
          <img
            className={styles.qrcode}
            src={require("assets/images/contact/new_manager_qrcode1.png")}
            alt=""
          />
          <span className={styles.name}>银风科技合作咨询</span>
          <span className={styles.phone}>联系电话：18011735822</span>
        </div>
      </Modal>
    );
  }
);

export default ContactModal;
