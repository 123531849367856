// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width: 960px) {\n  .scaleContainer_1Yn6BT {\n    max-width: unset !important;\n  }\n  .scaleContainer_1Yn6BT .ant-modal-body {\n    padding: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/Modal/ImageModal.tsx/index.module.less"],"names":[],"mappings":"AAAA;EACE;IACE,2BAAA;EACF;EAFA;IAKM,UAAA;EAAN;AACF","sourcesContent":["@media only screen and (max-width: 960px) {\n  .scaleContainer {\n    max-width: unset !important;\n\n    :global {\n      .ant-modal-body {\n        padding: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scaleContainer": "scaleContainer_1Yn6BT"
};
export default ___CSS_LOADER_EXPORT___;
